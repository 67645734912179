export default defineNuxtRouteMiddleware(async (to, from, next) => {
  const { user } = useAuthStore()

  if (user.group === 'Partner') {
    console.log('Partner')
    if (to.fullPath.includes('/admin/mandanten')) {
      return
    }
    console.log('redirecting to /admin/mandanten')
    return navigateTo('/admin/mandanten')
  }
  return
})
